import Tabs from './Widgets/Tabs'
import Shortcuts from './Widgets/Shortcuts'
import MyApps from './Widgets/MyApps'
import Indicateur from './Widgets/Indicateur'
import Actuality from './Widgets/Actuality'
import PageLoader from '../common/PageLoader'

const Home = () => {
    return (
        <div className="px-10 pb-4">
            <Tabs />
            <div className="mt-1 grid grid-cols-12 md:mt-6 2xl:mt-7.5 space-x-8">
                <div className="col-span-12 xl:col-span-12">

                    {/* <PageLoader /> */}
                    <MyApps />
                    {/* <Shortcuts /> */}
                    {/* <Indicateur /> */}
                </div>
                {/* <div className="col-span-12 xl:col-span-4">
                    <div className="mt-[4.2rem]">
                    <Actuality/>
                    </div>
                </div> */}
            </div>
            <footer className='text-center lg:text-end py-4' style={{position: "absolute", bottom: 0}}>
                Powered By KuTiWa @ {new Date().getFullYear()}  All rights reserved
            </footer>
        </div>
    )
}

export default Home