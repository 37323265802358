export class ExternalLinks {
    // static domain: string = "acquetrh.com"
    // static Facture = `https://go.${this.domain}`
    // static Stock = `https://go.${this.domain}`
    // static Caisse = `https://go.${this.domain}`
    // static RH = `https://rh.${this.domain}`
    // static QHSE = `https://rh.${this.domain}`
    // static Logistique = `https://logistics.${this.domain}`
    // static Pilotage = `https://pilotage.${this.domain}`

    static domain: string = "crid-cam.com"
    static Facture = `https://erp.${this.domain}`
    static Stock = `https://erp.${this.domain}`
    static Caisse = `https://erp.${this.domain}`
    static RH = `https://rh.${this.domain}`
    static QHSE = `https://rh.${this.domain}`
    static Logistique = `https://logistics.${this.domain}`
    static Pilotage = `https://erp.${this.domain}`
    static PortailPriseCommande = `https://portail-cx.${this.domain}`
}